<template>
  <div class="system">
    <Banner></Banner>
    <h2 class="h2">产品体系</h2>
    <div class="system_context">
      <swiper ref="mySwiper" :options="swiperOptions">
        <swiper-slide v-for="conext in contextList" :key="conext.title">
          <div class="context_item">
            <div class="title">{{ conext.title }}</div>
            <div class="text">{{ conext.text }}</div>
            <template v-for="item in conext.children">
              <div class="item_title" :key="item.itemTitle">{{ item.itemTitle }}</div>
              <div class="item_text" v-for="text in item.itemText">
                <img src="@/assets/img/pc/product/true.png" />
                <span>{{ text }}</span>
              </div>
            </template>
            <div class="btn" @click="trial">申请试用</div>
          </div>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>
    <FootMain></FootMain>
  </div>
</template>

<script>
import Banner from '@/components/m/product/product.vue'
import FootMain from '@/components/m/foot_main/index.vue'
export default {
  data() {
    return {
      swiperOptions: {
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        loop: true,
        observer: true,
        loop: true,
        autoplay: {
          delay: 3000000, //自动切换的时间间隔，单位ms
          disableOnInteraction: false //用户操作swiper之后，是否禁止autoplay
        }
      },
      contextList: [
        {
          title: '终端并发数（STA）',
          text: '系统所能支持的最大在线设备数量',
          children: [
            {
              itemTitle: '员工管理',
              itemText: ['授权开启', '终端限制及绑定', '带宽设定']
            },
            {
              itemTitle: '黑白名单',
              itemText: ['MAC白名单']
            },
            {
              itemTitle: '策略配置',
              itemText: ['上网时段', '上网时长', '带宽设定']
            }
          ]
        },
        {
          title: 'AC授权',
          text: '系统允许接入的无线控制器或网 关数量',
          children: [
            {
              itemTitle: '控制器管理',
              itemText: ['添加维护控制器', '认证方式开启']
            },
            {
              itemTitle: '认证模板',
              itemText: ['添加维护认证模板页']
            }
          ]
        },
        {
          title: '定制开发',
          text: '根据用户自身需求进行定制性开 发，最终费用由工作量决定',
          children: [
            {
              itemTitle: '认证方式',
              itemText: ['增加认证方式，包含数据源等', '兼容其他厂商设备', '对接第三方系统', '其他需求']
            },
            {
              itemTitle: '认证页面',
              itemText: ['需要对认证页个性化定制']
            }
          ]
        }
      ]
    }
  },
  components: {
    Banner,
    FootMain
  },
  methods: {
    trial() {
      this.$router.push('/m/trial')
    }
  }
}
</script>

<style lang="scss" scoped>
.system {
  background-color: white;
}
h2 {
  margin: 1.315789rem 0 1rem !important;
}
.system_context {
  width: 31.914893rem;
  margin: 0 auto;
  background-color: white;
  padding-top: 1rem;
  // display: flex;
  // justify-content: space-between;

  overflow: hidden;
  .context_item {
    position: relative;
    width: 80%;
    margin: 0 auto;
    // height: 25.131578rem;
    min-height: 40rem;
    background-color: white;
    // border: 1px solid #aaa;
    // box-shadow: 0px 0px 7px 4px #f1f1f1;
    box-shadow: 0px 0px 7px 4px #f1f1f1;
    border-radius: 0.526315rem;
    padding: 1.18421rem 2rem;
    box-sizing: border-box;
    margin-bottom: 3rem;
    margin-top: 0.4255rem;
    .title {
      font-size: 1.5rem;
      color: #333;
      text-align: center;
      margin-bottom: 0.789473rem;
    }

    .text {
      font-size: 1.4rem;
      color: #666;
      line-height: 2rem;
      // margin: 0.789473rem 0;
    }

    .item_title {
      font-size: 1.3rem;
      color: #333;
      // margin: 0.394736rem 0;
      margin: 0.8rem 0;
      line-height: 2rem;
    }

    .item_text {
      // height: 1.4rem;
      height: 2rem;

      display: flex;
      align-items: center;
      img {
        height: 1.4rem;
        margin-right: 0.526315rem;
        width: 1.4rem;
      }
      font-size: 1.3rem;
      color: #666;
      margin-bottom: 0.8rem;
      line-height: 1.4rem;
    }
  }

  .btn {
    width: 11.842105rem;
    height: 3rem;
    box-sizing: border-box;
    border: 1px solid #59b839;
    position: absolute;
    bottom: 1.18421rem;
    left: 50%;
    transform: translateX(-50%);
    font-size: 1.4rem;
    line-height: 3rem;
    color: #66ba22;
    text-align: center;
    border-radius: 0.4rem;
    cursor: pointer;
  }
}

.h2 {
  text-align: center;
  font-size: 1.578947rem;
  color: #333;
  margin: 0;

  &:before {
    display: inline-block;
    content: '';
    height: 0.263157rem;
    width: 5.526315rem;
    background-color: #11e323;
    vertical-align: middle;
    transform: translateY(-50%);
    margin: 0 0.394736rem;
  }

  &:after {
    display: inline-block;
    content: '';
    height: 0.263157rem;
    width: 5.526315rem;
    background-color: #11e323;
    vertical-align: middle;
    transform: translateY(-50%);
    margin: 0 0.394736rem;
  }
}
</style>
