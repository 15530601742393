<template>
  <div class="banner">
    <div class="banner_context">
      <h2>网络统一准入系统</h2>
      <div class="text">针对身份不同，设置不同的上网权限</div>
      <div class="text">PC、移动端安全接入</div>
      <img src="@/assets/img/m/product/banner_img.png" />
      <div class="download">
        <div><img src="@/assets/img/m/product/win.png" />Windows Server</div>
        <div><img src="@/assets/img/m/product/linux.png" />Linux CentOS</div>
      </div>
      <div class="btn" @click="download">立即下载</div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    download() {
      // if(this.$route.path!='/m/download'){
      this.$router.push('/m/download')
      // this.$nextTick(()=>{
      // 	document.documentElement.scrollTop=0
      // })
      // 	}else{
      // 	// 	return
    }
    // }
  }
}
</script>

<style lang="scss" scoped>
/* .product_context{
		// height: 27.368421rem;
		padding-top: 3rem;
		padding-bottom: 3rem;
		box-sizing: border-box;
		background-image: url("~@/assets/img/pc/product/top_banner.jpg");
		background-size: 100% 100%;
		background-repeat: no-repeat;
	
		.banner{
			color: white;
			width: 31.914893rem;
			margin: 0 auto;
			height: 15.789473rem;
			display: flex;
			box-sizing: border-box;
			justify-content: center;
			align-items: center;
			.banner_context{
				.transverse{
				background-color: white;
				border-radius: 0.131578rem;
				width: 3.68421rem;
				height: 0.263157rem;
				}
				h2{
					font-size: 1.842105rem;
					margin: 1.315789rem 0;
				}
				.context_text{
					font-size: 0.789473rem;
				}
				.context_img{
					height: 2.105263rem;
					display: flex;
					align-items: center;
						font-size: 0.789473rem;
					img{
						height: 100%;
					}
				}
				
			}
			.img{
				max-width: 14rem;
				height: 7rem;
			}
			
		}
		.btn{
			width: 12.765957rem;
			height:4.255319rem;
			margin: 0 auto;
			border-radius: 4.255319rem;
			border: none;
			color: #7d6bef;
			font-size: 1.276595rem;
			line-height: 4.255319rem;
			cursor: pointer;
			background-color: #fff;
			text-align: center;
		}
	} */
.banner_context {
  overflow: hidden;
}
.banner {
  width: 31.914893rem;
  height: 45.957446rem;
  overflow: hidden;
  margin: 0 auto;
  background-image: url('~@/assets/img/m/product/nav_banner.jpg');
  color: white;
  text-align: center;
  h2 {
    font-size: 2.80851rem;
    margin: 3.404255rem 0 1.914893rem;
  }
  .text {
    font-size: 1.276595rem;
    margin-bottom: 1.276595rem;
  }
  img {
    width: 29.361702rem;
    // height: 21.702127rem;
  }
  .download {
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 1.276595rem;
    margin-top: 1.276595rem;
    div {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
    img {
      width: 4.255319rem;
      height: 4.255319rem;
    }
  }
  .btn {
    width: 29.361702rem;
    height: 3.829787rem;
    border: transparent;
    border-radius: 1.914893rem;
    background-color: #ff9600;
    font-size: 1.531914rem;
    line-height: 3.829787rem;
    margin: 0 auto;
    margin-top: 2.978723rem;
  }
}
</style>
